@import './_variables.scss';

.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: $white;
  justify-content: center;
  .col {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
    &.img {
      position: relative;
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #091b3b;
      @media screen and (max-width: $sm-screen) {
        display: none;
      }
      img {
        width: 100%;
        object-fit: contain;
      }    
    }
    .login-content {
      max-width: 410px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1{
        font-family: "Montserrat-Bold";
        margin-bottom: $margin-xs;
        font-size: 28px;
      }
  
      h1,h2{
        color: $primary;
      }
  
  
      h2{
        margin-top: 0;
        text-align: center;
        margin-bottom: $margin-md;
        font-family: "Montserrat-SemiBold";
        font-size: 20px;
        margin-bottom: 30px;
      }

      .address{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-md;
        img{
          width: 110px;
          margin: 0 $margin-sm ;
        }
        p{
          text-align: left;
          color: $primary;
          font-size: 14px;
          font-family: "Montserrat-SemiBold";
          margin: 0 $margin-xs;
  
        }
      }  

      .input-login {
        width: 100%;
        height: 40px;
        border-radius: 14px;
        margin-top: 20px;
        padding: 0px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba($color: #0A1760, $alpha: 0.28);
        img {
          max-width: 20px;
        }
        input {
          font-size:15px;
          height: 80%;
          width: 80%;
          border: 0px;
          color: $primary;
          font-family: "Montserrat-Regular", sans-serif;
          &::placeholder {
            color: rgba($color: #0A1760, $alpha: 0.43)
          }
          &:-webkit-autofill,
          &:-webkit-autofill:focus {
            font-family: "Montserrat-Regular", sans-serif;
            transition: background-color 0s 600000s, color 0s 600000s !important;
          }
        }
      }
      .login-message {
        font-size: 14px;
        min-height: 40px;
        margin: 10px;
        display: flex;
        text-align: center;
        align-items: center;
      }
      .btn-connection {
        @include btn;
        margin-top: 20px;
        background-color: $orange;
        color: $white;
        min-width: 200px;
        min-height: 35px;
        text-transform: uppercase;
        font-size: 16px;
        padding: 5px 15px;
        border-radius: 24px;
        &.disable {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
      .btn-forgot {
        @include btn;
        color: $dark;
        font-size: 13px;
        border-bottom: 1px solid $primary;
      }
    }
  }
}
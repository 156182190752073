@import '../../../styles/variables';

.btn {
  position: relative;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 1px solid #FF8900;
    border-radius: 36px;
    margin-right: 10px;
    background-color: transparent;
    cursor: pointer;
    img {
      max-width: 30px;
      &.loading {
        animation: rotate-animation 1s infinite linear;
      }
    }
    &:hover {
      background-color: rgba(#FF8900, 0.2);
    }
    &.download {
      border: 1px solid $success;
      background-color: $success;
      &:hover {
        background-color: rgba($success, 0.6);
      }
    } 
  }
  .tooltip {
    left: -60%;
    opacity: 0;
    top: 40px;
    position: absolute;
    width: fit-content;
    block-size: fit-content;
    display:inline-block;
    font-size: 13px;
    width: auto;
    z-index: 100;
    text-align: center;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0px 2px 13px -2px rgba(156, 167, 177, 0.5);
    background-color: $white;
    word-break: keep-all;
  }
  &:hover {
    .tooltip {
      opacity: 1;
    }
  } 
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
  }
  50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.tooltip{
  display: inline-flex;
  vertical-align: bottom;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 5px;
  font-family: "MontSerrat-SemiBold";
}

.content-tooltip{
  padding: $margin-sm;
  font-style: $regular;
  p{
    line-height: 18px;
    font-size: $small;
    margin: 0;
  }
}

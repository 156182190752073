@import './_variables.scss';

.shape{
  ul{
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: $radius;
    overflow: hidden;
    margin-bottom: 20px;

    @media screen and (min-width: #{$sm-screen}) {
      flex-direction: row;
    }

    li{
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      list-style: none;
      background-color: $primary;
      &:hover {
        background-color: $primary-dark;
      }
      padding: $margin-sm $margin-sm;

      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: column;
      }

      p{
        font-family: "Montserrat-SemiBold";
        font-style: $h2;
        text-transform: uppercase;
        color:white;
        box-sizing: border-box;
        padding: 0 $margin-sm;
      }

      img{
        max-height: 90px;
        margin-bottom: 0;
        @media screen and (min-width: #{$md-screen}) {
          height: 130px;
          max-height: 130px;
          margin-bottom: $margin-sm;
        }
      }
      &.selected{
        background-color: $orange;
      }
    }
  }

  section{
    padding: 0;
  }
  .warn-shape{
    font-family: "Montserrat-SemiBold";
    img{
      position: relative;
      width: 20px;
      top:2px;
      margin-right: 5px;
    }
  }
}
@import './_variables.scss';

.choose-showroom-type{
  button  {
    padding: 0 30px;
    font-size: 14px;
    height: 45px;
    border-radius: 22.5px;
    min-width: 150px;
    outline: none;
    transition: all .3s ease-in-out;
    background-color: $orange;
    border: solid 1px $orange;
    color: $white;
  }
  .choix{
    display: flex;
    flex-direction: column;
    @media screen and (min-width: #{$sm-screen}) {
      flex-direction: row;
    }

    section{
      cursor: pointer;
    }
    button{
      cursor: pointer;
    }

  }

  .choix-2-options{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: #{$md-screen}) {
      flex-direction: row;
    }
  }


  section{
    width: 100%;
    &.not-active {
      opacity: 0.4
    }

    @media screen and (min-width: #{$md-screen}) {
      margin-top: $margin-sm;
    }

    &.disabled{
      opacity: 0.5;
      button{
        pointer-events: none;
      }
    }

    &.choix-2-option-1,
    &.choix-2-option-2{
      width: auto;
      max-width: 100%;
      @media screen and (min-width: #{$md-screen}) {
        width: 47%;
        max-width: 47%;
      }
    }

    button{
      margin-top: $margin-sm;
      min-width: 200px;
    }

    h4{
      margin: $margin-xs 0 $margin-sm 0;
    }

    &.choix-2-option-1{
      display: flex;
      // flex:1;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 !important;
      // margin: 15px 20px 20px 0px;

      >div:nth-child(1){
        padding: $margin-sm $margin-md;
      }

      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: row;
      }

      .css-2b097c-container{
        margin: $margin-sm 0;
      }

    }

    .option-1{
      display: flex;
      flex-direction: column;
      &.not-active {
        opacity: 0.5
      }
      @media screen and (min-width: #{$sm-screen}) {
        width: 50%;
        margin-top: 0;
      }

      >div{
        display: flex;
        flex:1;
        align-items: center;
        background-color: $primary;
        color: $white;
        padding: $margin-md;
        cursor: pointer;
        &:hover {
          background-color: $primary-dark;
        }

        img{
          width: 40%;
          margin-right: $margin-sm;
        }


        @media screen and (min-width: #{$sm-screen}) {
          margin: 0;
          padding: $margin-md;
        }

        &:first-child{
          border-radius: $radius $radius 0 0;
          margin-top: 0;
        }

        &:last-child{
          border-radius: 0 0 $radius $radius;
        }


        &.selected{
          background-color: $orange;
        }
      }
    }

    &.choix-2-option-2{
      .input-dimension{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 5px;
        .container-input{
          width: 100%;
        }
        input {
          margin: 10px 5px;
          border: 1px solid rgba($color: #0A1760, $alpha: 0.28);
          height: 40px;
          border-radius: 13px;
          padding: 0px 15px;
          max-width: 80px;
        }
        span{
          padding: 0 5px;
        }
      }
    }

  }
}

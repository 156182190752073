@import '../../../../styles/variables';

.container {
  @include page-container;
  .back {
    padding: 30px 0px 0px 30px;
    a {
      display: flex;
      text-decoration: none;
      color: $dark;
      img {
        width: 18px;
      }
      p {
        margin: 0px 10px;
      }
    }
  }
  .header {
    @include page-header;
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $md-screen) {
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
      flex-direction: column;
      align-items: flex-start;
    }
    .actions {
      width: 300px;
      min-width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media screen and (max-width: $md-screen) {
        margin-top: 10px;
        width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: space-between;
      }
      @media screen and (max-width: $sm-screen) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      .delete {
        @include btn;
        color: $error;
        &:hover {
          text-decoration: underline;
        }
        margin-right: 20px;
        @media screen and (max-width: $sm-screen) {
          display: none;
        }
      }
      .submit {
        @include btn-edit;
        padding: 8px 20px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: $sm-screen) {
          position: fixed;
          bottom: 20px;
          right: 20px;
        }
      }
    }
  }
  .content {  
    @include row;
    justify-content: space-between;
    padding: 0px 30px;
    @media screen and (max-width: $sm-screen) {
      padding: 20px;
    }
    .block {
      @include block;
      margin-bottom: 30px;

      .archived {
        label {
          font-family: "Montserrat-SemiBold", sans-serif;
          display: block;
          font-size: 14px;
          margin-bottom: 4px;
        }
        p {
          margin-bottom: 5px;
        }
      }
      h2 {
        margin-bottom: 10px;
      }
      .row {
        @include row;
        justify-content: space-between;
        a {
          color: $primary;
          &:hover {
            text-transform: underline;
          }
        }
        .error {
          margin-top: 15px;
          font-size: 14px;
          color: $error;
        }
        .toggles {
          margin-bottom: 10px;
          button {
            width: 150px;
            background-color: transparent;
            padding: 10px 20px;
            margin-right: 10px;
            border-radius: 7px;
            border: 1px solid $primary;
            color: $primary;
            cursor: pointer;
            @include transition;
            &:hover {
              background-color: rgba($primary, 0.1);
            }
            &.active {
              color: $white;
              background-color: $primary;
            }
          }
        }
        &.nested {
          padding-left: 20px;
          .col-2 {
            width: 48%;
          }
        }
        &.border {
          border-bottom: 1px solid rgba($dark, 0.1);
          margin-bottom: 20px;
        }
        .image {
          width: 100%;
          max-height: 300px;
          display: flex;
          justify-content: center;
          margin: 20px 0px;
          img {
            width: 100%;
            object-fit: contain;
          }
        } 
      }
      .adventages {
        margin-bottom: 10px;
        .error {
          margin-top: 10px;
          font-size: 14px;
          color: $error;
        }
        .list {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;
          .item {
            display: flex;
            align-items: center;
            width: 30%;
            cursor: pointer;
            .icon {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background-color: #BEBEBE;
              margin-right: 8px;
              min-width: 40px;
              &.active {
                background-color: $orange;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
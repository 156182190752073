@import './_variables.scss';
.dashboard-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.mb-20 {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  .col-1 {
    width: 100%;
  }
  .col-2-3 {
    width: calc(66.6% - 20px);
    @media screen and (max-width: 1025px) {
      width: 100%;
    }
  }
  .col-1-3 {
    width: calc(33.3%);
    @media screen and (max-width: 1025px) {
      width: 100%;
    }
  }
  .col-2 {
    width: 49%;
    margin-bottom: 20px;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }
}
.dashboard-card {
  width: auto;
  border-radius: 6px;
  background-color: $white;
  box-shadow: $shadow;
  .number-container {
    margin: 20px 0px 0px 20px;
    &.col-2 {
      margin: 20px 0px 0px 20px;
    }
  }
  .number-xl {
    font-size: 39px;
  }
  .number {
    font-size: 30px;
  }
  .number-icon {
    display: flex;
    margin-top: 4px;
    img {
      margin-left: 10px;
    }
  }
  &.providers {
    min-height: 300px;
    background-color: $primary;
    color:$white;
    text-transform: uppercase;
    justify-content: space-between;
    .main-infos {
      height: 100%;
      min-height: 339px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .prestas {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 300px;
      height: 100%;
      margin-bottom: 0px;
      background-color: $primary-dark;
      border-radius: 0px 6px 6px 0px;
      padding: 20px;
      .number-container {
        margin: 0px;
      }
      @media screen and (max-width: 1025px) {
        border-radius: 0px 0px 6px 6px;
        min-height: 300px;
        width: calc(100% - 40px);
      }
    }
  }
  &.shops {
    min-height: 340px;
    background-color: $orange-light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color:$white;
    text-transform: uppercase;
    @media screen and (max-width: 1025px) {
      margin-top: 20px;
    }
    .days-container {
      display: flex;
      justify-content: space-between;
      width: auto;
      padding: 20px;
      background-color: $orange;
      border-radius: 0px 0px 6px 6px;
      .day {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
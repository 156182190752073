@mixin remove-highlight(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin page-container {
  width: calc(100% - 240px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 240px;
  animation-name: slide-left;
  animation-duration: 0.5s;
  min-height: 100%;
  @media screen and (max-width: $sm-screen) {
    justify-content: flex-start;
    width: 100vw;
    margin-left: 0px;
    min-height: 100%;
  }
}

@mixin page-header {
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  margin: 30px auto;
  &.space-between {
    justify-content: space-between;
  }
  @media screen and (max-width: $sm-screen) {
    margin: 0px;
    width: 100%;
    padding: 15px 20px;
    position: fixed;
    background-color: $bg-grey;
    z-index: 4;
    box-shadow: $shadow;
  }
  h1 {
    @media screen and (max-width: $xs-screen) {
      max-width: 70%;
      font-size: 16px;
    }
  }
}

@mixin btn-edit {
  @include btn;
  min-width: 80px;
  font-size: 13px;
  padding: 4px;
  color: $white;
  border-radius: 20px;
  background-color: $orange;
  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }
  &.large {
    padding: 10px 20px;
  }
  &.circle {
    min-width: 36px;
    min-height: 36px;
    padding: 0px;
    border-radius: 28px;
    img {
      width: 15px;
    }
  }
  &.offer {
    padding: 10px;
    color: $white;
    border-radius: 13px;
    margin: 10px 10px 10px 0px;
    background-color: $primary;
  }
  &.add-offer {
    padding: 5px 10px;
    min-width: 37px;
    color: $white;
    border-radius: 13px;
    margin: 10px;
    font-size: 21px;
    background-color: $primary;
  }
  &.success {
    background-color: $success;
  }
  &:hover {
    box-shadow: 0px 2px 13px -2px  rgba(156, 167, 177, 0.90);
  }
}

@mixin loader(){
  .loader{
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: lighten($primary-color,30%);
      border-radius: 5px;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: rotate;
      margin-left: 12px;
      &.show{
        display: inline-block;
      }
  }

  @keyframes rotate {
      0% {
        transform: rotateX(0) rotateY(0);
        }
      50% {
        transform: rotateX(-180deg) rotateY(0);
      }
      100% {
        transform: rotateX(-180deg) rotateY(-180deg);
      }
  }
}

@mixin row {
  display: flex;
  &.flex-end {
    justify-content: flex-end;
    align-items: flex-start;
  }
  &.space-between {
    justify-content: space-between;
    align-items: flex-start;
  }
  &.col-2 {
    justify-content: space-between;
  }
  .search-icon {
    position: absolute;
    width: 20px;
    pointer-events: none ;
    right: 0px;
    transform: translate(-10px , 15px);
  }
  @media screen and (max-width: $md-screen) {
    flex-direction: column;
    justify-content: flex-start;
    &.export {
      flex-direction: row;
      justify-content: flex-start;
      @media screen and (max-width: $sm-screen) {
        flex-direction: column;

      }
    }
  }
  .sort-item {
    font-size: 13px;
    color: rgba($dark, 0.6);
    cursor: pointer;
    &:hover {
      color: rgba($dark, 0.8);
    }
    img {
      margin-left: 5px;
      @include transition;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .col-1 {
    width: 100%;
  }
  .col-2 {
    width: 49%;
    margin-bottom: 20px;
    @media screen and (max-width: $md-screen) {
      width: 100%;
    }
  }
  .col-70 {
    width: 70%;
    margin-bottom: 20px;
    @media screen and (max-width: $md-screen) {
      width: 100%;
    }
  }
  .col-3 {
    width: calc(33.3% - 20px);
    margin-bottom: 1%;
    margin-right: 20px;
    @media screen and (max-width: $md-screen) {
      width: 100%;
      max-width: 100%;
    }
  }
}

@mixin block {
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 20px;
  h2 {
    @include bold;
    color: $primary;
  }
}


@mixin bold {
  font-family: "Montserrat-SemiBold", sans-serif;
}

@mixin input {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  position: relative;
  &.offer-number {
    width: 49px;
    input {
      text-align: center;
    }
  }
  label {
    margin-left: 0px;
  }
  input {
    margin: 5px 0px;
    border: solid 1px #B2B2B2;
    height: 37px;
    font-size: 14px;
    border-radius: 12px;
    padding: 0px 10px;
    &.error {
      border: 1px solid $error;
    }
  }
  .select {
    margin: 5px 0px;
    height: 37px;
    padding: 0px 0px;
  }
  &.secondary {
    input, textarea {
      border-radius: 3px;
      background: #FBFBFB;
      border: 1px solid #DFDFDF;
    }
    textarea {
      margin: 5px 0px;
      max-width: 100%;
      min-height: 120px;
      padding: 5px;      
    }
    .checkbox {
      margin-top: 5px;
      display: flex;
      align-items: center;
      img {
        width: 25px;
        margin-right: 10px;
      }
    }
  }  
}
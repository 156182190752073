@import './_variables.scss';

.main-nav {
  min-height: 100vh;
  max-height: 100%;
  width: 190px;
  top:0;
  z-index: 202;
  position: fixed;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease;
  overflow: auto;
  @media screen and (max-width: $sm-screen) {
    transform: translate(-100vw, 0px);
    width: 100vw;
    max-height: 100%;
    overflow-x:hidden;
    &.mobile {
      transform: translate(0px, 0px);
    }
  }
  .user-profile {
    min-height: 80px;
    margin:20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid rgba($grey, 0.2);
    span {
      font-size: 16px;
      white-space: normal;
      &.role {
        font-size: 12px;
        color: $primary;
      }
    }
    .btn-logout {
      margin-top: 10px;
      @include btn;
      &:hover {
        color: $orange;
      }
    }
  }
  .languages {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      @include btn;
      text-transform: uppercase;
      margin: 0px 10px;
      padding: 5px 10px;
      color: $grey;
      &:hover {
        background-color: $orange;
        color: $white;
      }
    }    
  }
  .logo-container {
    width: 100%;
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    font-size: 13px;
    background-color: $white;
    padding: 15px 0px;
    z-index: 203;
    img {
      width: 38px;
      margin-left: -15px;
      margin-right: 5px;
    }
  }
  li {
    margin: 25px 0px;
    padding: 0px 20px;
    transition: all 0.5s ease;
    &.reception {
      margin: 0px 0px;
      margin-top: -10px;
      padding: 10px 20px;
      z-index: 206;
      cursor: pointer;
      &.isActive {
        background-color: transparent;
      }
      &:hover {
        background-color: transparent;
        color: $dark;
      }
      ul {
        display: none;
        &.isActive {
          display: block;
        }
        li {
          margin: 20px 0px;
          padding: 0px 0px 0px 0px;
          font-size: 11px;
          a {
            color: $dark;
            &.isActive {
              color: $primary;
            }
          }
        }
      }
    }
    .btn-nav {
      display: flex;
      align-items: center;
      color: $dark;
      &:hover {
        color: $orange;
      }
      &.reception {
        &:hover {
          color: $dark;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
      .nav-label {
        font-size: 13px;
        margin-left: 10px;
        // &.active {
        //   color: $orange;
        // }
      }
    }
  }
}

.btn-nav-toggle {
  display: none;
  @media screen and (max-width: $sm-screen) {
    display: block;
    top: 0;
    right: 0;
    z-index: 204;
    position: fixed;
    @include btn;
    margin: 15px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
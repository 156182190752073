@import './mixin';

$primary: #005FBF;
$primary-dark: #074C91;
$dark: #161B46;
$text-color : #161B46;
$white: #FFFFFF;
$bg-grey: #F9F9FB;
$orange: #FF8900;
$orange-light: #F5A623;
$grey: #81848B;
$error: #FF0009;
$success: #00BA38;
$md-grey : #B2B2B2;

$text-disabled-color : #797C94;

$shadow: 0px 0px 13px -2px rgba(156,167,177,0.30);

$lg-screen:1200px;
$md-screen: 1025px;
$sm-screen: 768px;
$xs-screen: 576px;

$radius : 6px;
$small : 14px;
$regular : 14px;
$h2 : 24px;
$h1 : 28px;
$margin-md : 30px;
$margin-sm : 15px;
$margin-xs : 10px;

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@mixin btn {
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
}


@mixin transition {
  transition: all 0.2s ease-in-out;
}